import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Routes } from '../shared/enums/routes';
import { Page404 } from './404/index';
import { ErrorPage } from './error';
import { SuccessPage } from './success';
import { Home } from './home';

export const AppRouter = () => {
  useEffect(() => {
    const onResize = () => {
      let innerHeight = window.innerHeight;
      document.documentElement.style.setProperty('--app-height', `${innerHeight}px`);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.Home} component={Home} />
        <Route exact path={Routes.Garanty} component={ErrorPage} />
        <Route exact path={Routes.Page} component={SuccessPage} />
        <Route path={Routes.Rsl} component={Page404} />
        <Redirect from='*' to={Routes.Home} />
      </Switch>
    </BrowserRouter>
  );
};
